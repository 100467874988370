import React, { useEffect, useState } from 'react'

import { Form } from 'semantic-ui-react'

import { RECORD_FILTER_FORM_ID } from 'assets/constants/forms'
import { eventCodes } from 'assets/constants/notifications'
import useAuth from 'hooks/useAuth'
import useDialog from 'hooks/useDialog'
import useNotifications from 'hooks/useNotifications'
import businessService from 'services/business'
import recordsService from 'services/records'
import styles from './RecordFilterForm.module.scss'


const RecordFilterForm = (props) => {
    const { initialValues, onSubmit } = props

    const { userGroups } = useAuth()
    const { openErrorDialog } = useDialog()
    const { receivedEvent, eventNumber } = useNotifications()
    const [data, setData] = useState({})
    const [partners, setPartners] = useState(null)
    const [insureds, setInsureds] = useState(null)
    const [recordTypes, setRecordTypes] = useState(null)
    const recordStatuses = [
        {key: 'open', value: 'open', text: 'Abierto'},
        {key: 'closed', value: 'closed', text: 'Cerrado'},
    ]
    const itemsLiterals = {
        PARTNERS: 'asociados',
        INSUREDS: 'asegurados',
        RECORD_TYPES: 'tipos de expediente',
    }

    const allLoaded = !!partners && !!insureds && !!recordTypes

    const handleChange = (event, result) => {
        const { name, value } = result || event.target
        setData({
            ...data,
            [name]: value
        })
    }

    const storeOptions = (data, setter) => {
        const options = data.map((elem) => ({
            key: elem.id,
            value: elem.id,
            text: `${elem.name}${elem.tax_id ? ` (${elem.tax_id})` : ''}`,
        }))
        setter(options)
    }

    const handleError = (error, itemLiteral) => {
        let errorMessage = error?.response?.data?.error

        if (!errorMessage) {
            errorMessage = `Ha tenido lugar un error al cargar los ${itemLiteral}.
                            Por favor, inténtalo de nuevo más tarde o
                            contacta con el administrador de la plataforma.`
        }

        openErrorDialog({
            title: 'Error al cargar',
            content: errorMessage,
            size: 'tiny',
        })
    }

    const fetchPartners = () => {
        businessService.getFilterPartnersCatalogue()
            .then((res) => storeOptions(res.data, setPartners))
            .catch((error) => handleError(error, itemsLiterals.PARTNERS))
    }

    const fetchInsureds = () => {
        businessService.getFilterInsuredsCatalogue()
            .then((res) => storeOptions(res.data, setInsureds))
            .catch((error) => handleError(error, itemsLiterals.INSUREDS))
    }

    const fetchRecordTypes = () => {
        recordsService.getRecordTypesCatalogue()
            .then((res) => storeOptions(res.data, setRecordTypes))
            .catch((error) => handleError(error, itemsLiterals.RECORD_TYPES))
    }

    useEffect(() => {
        if (!userGroups) {
            return
        }

        if (userGroups.brokers) {
            setPartners([])
        }

        if (userGroups.consultants) {
            fetchPartners()
        }

        fetchInsureds()
        fetchRecordTypes()
    }, [userGroups])

    useEffect(() => {
        const relevantPartnerEvents = [eventCodes.PARTNER_CREATED, eventCodes.PARTNER_DELETED, eventCodes.PARTNER_UPDATED]
        const relevantInsuredEvents = [eventCodes.INSURED_CREATED, eventCodes.INSURED_DELETED, eventCodes.INSURED_UPDATED]
        const relevantRecordTypeEvents = [eventCodes.RECORD_TYPE_CREATED, eventCodes.RECORD_TYPE_DELETED, eventCodes.RECORD_TYPE_UPDATED]

        if (relevantPartnerEvents.includes(receivedEvent?.code) && userGroups?.consultants) {
            fetchPartners()
        }
        if (relevantInsuredEvents.includes(receivedEvent?.code)) {
            fetchInsureds()
        }
        if (relevantRecordTypeEvents.includes(receivedEvent?.code)) {
            fetchRecordTypes()
        }
    }, [receivedEvent?.code, eventNumber])

    useEffect(() => {
        setData({
            partners: initialValues?.partners || [],
            insureds: initialValues?.insureds || [],
            record_types: initialValues?.record_types || [],
            from_date: initialValues?.from_date || '',
            until_date: initialValues?.until_date || '',
            status: initialValues?.status || '',
        })
    }, [initialValues])

    return (
        <Form
            id={RECORD_FILTER_FORM_ID}
            className={styles.RecordFilterForm}
            onSubmit={() => onSubmit(data)}
        >
            <Form.Group widths='equal'>
                <Form.Input
                    type='date'
                    label='Desde fecha'
                    placeholder='Desde fecha'
                    name='from_date'
                    disabled={!allLoaded}
                    value={data.from_date || ''}
                    onChange={handleChange}
                />
                <Form.Input
                    type='date'
                    label='Hasta fecha'
                    placeholder='Hasta fecha'
                    name='until_date'
                    disabled={!allLoaded}
                    value={data.until_date || ''}
                    onChange={handleChange}
                />
            </Form.Group>
            {
                (userGroups?.consultants || (userGroups?.brokers && insureds?.length > 0)) &&
                <Form.Group widths='equal'>
                    {
                        userGroups?.consultants &&
                        <Form.Select
                            multiple
                            search
                            disabled={!partners}
                            loading={!partners}
                            options={partners || []}
                            label='Asociado'
                            placeholder='Asociado'
                            name='partners'
                            value={data.partners || []}
                            onChange={handleChange}
                        />
                    }
                    <Form.Select
                        multiple
                        search
                        disabled={!insureds}
                        loading={!insureds}
                        options={insureds || []}
                        label='Asegurado'
                        placeholder='Asegurado'
                        name='insureds'
                        value={data.insureds || []}
                        onChange={handleChange}
                    />
                </Form.Group>
            }
            <Form.Group widths='equal'>
                <Form.Select
                    multiple
                    search
                    disabled={!recordTypes}
                    loading={!recordTypes}
                    options={recordTypes || []}
                    label='Tipo de expediente'
                    placeholder='Tipo de expediente'
                    name='record_types'
                    value={data.record_types || []}
                    onChange={handleChange}
                />
                <Form.Select
                    search
                    clearable
                    disabled={!allLoaded}
                    options={recordStatuses}
                    label='Estado de expediente'
                    placeholder='Estado de expediente'
                    name='status'
                    value={data.status || ''}
                    onChange={handleChange}
                />
            </Form.Group>
        </Form>
    )
}


export default RecordFilterForm