const TOKEN_REFRESH_HOURS_THRESHOLD = 1
const MINUTES_UNTIL_LOGOUT = 30

const authGroups = {
    ADMINISTRATORS: 'administrators',
    BROKERS: 'brokers',
    CHIEFS: 'chiefs',
    CONSULTANTS: 'consultants',
    all(excludeAdmin = true) {
        const groups = [this.BROKERS, this.CHIEFS, this.CONSULTANTS]

        if (!excludeAdmin) {
            groups.unshift(this.ADMINISTRATORS)
        }

        return groups
    }
}

export {
    MINUTES_UNTIL_LOGOUT,
    TOKEN_REFRESH_HOURS_THRESHOLD,
    authGroups
}