import React from 'react'

import { Helmet } from 'react-helmet-async'
import { Header, Icon, Message } from 'semantic-ui-react'

import PageTitle from 'components/UI/PageTitle/PageTitle'
import PasswordChangePanel from 'components/PasswordChangePanel/PasswordChangePanel'
import PersonalInfoPanel from 'components/PersonalInfoPanel/PersonalInfoPanel'
import ProfilePicturePanel from 'components/ProfilePicturePanel/ProfilePicturePanel'
import useAuth from 'hooks/useAuth'
import styles from './MyProfile.module.scss'


const MyProfile = () => {
    const { updateUser, user, userGroups } = useAuth()

    const generatePermissionsMessage = () => {
        let roleType
        let content

        if (userGroups?.consultants) {
            roleType = 'asesor'
            content = 'Tienes acceso a todos los expedientes de todas las corredurías.'
        } else if (userGroups?.chiefs) {
            roleType = 'administrador de correduría'
            content = 'Tienes acceso a todos los expedientes de tu correduría.'
        } else {
            roleType = 'corredor'
            content = 'Tienes acceso únicamente a los expedientes de tu correduría dados de alta por ti.'
        }

        return (
            <Message icon info className={styles.InfoMessage}>
                <Icon name='info circle' className={styles.MessageIcon} />
                <Message.Content>
                    <Message.Header>
                        Tu perfil en la plataforma es: <span className={styles.RoleType}>{roleType}</span>
                    </Message.Header>
                    {content}
                </Message.Content>
            </Message>
        )
    }

    return (
        <>
            <Helmet>
                <title>Mi cuenta - CenterIuris</title>
            </Helmet>
            <PageTitle
                icon='user circle outline'
                header='Mi cuenta'
                subheader='Consulta y/o modifica tus datos'
            />
            {generatePermissionsMessage()}
            <div className={styles.PersonalPanels}>
                <PersonalInfoPanel
                    className={styles.ProfilePanel}
                    onUpdate={(newData) => updateUser(newData)}
                    user={user}
                />
                <ProfilePicturePanel
                    className={styles.ProfilePicturePanel}
                    onUpdate={(newData) => updateUser(newData)}
                    user={user}
                />
            </div>
            <PasswordChangePanel className={styles.ProfilePanel} />
        </>
    )
}

export default MyProfile
