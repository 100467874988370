import React, { useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { Dropdown, Icon, List } from 'semantic-ui-react'

import { messageType } from 'assets/constants/communication'
import { paths } from 'assets/constants/navigation'
import { getFileIconProps, datetimeToStr } from 'assets/utils/common'
import RoundIconButton from 'components/UI/RoundIconButton/RoundIconButton'
import useAuth from 'hooks/useAuth'
import useDialog from 'hooks/useDialog'
import communicationService from 'services/communication'
import MessageOptions from './MessageOptions'
import styles from './MessageItem.module.scss'


const MessageItem = (props) => {
    const { message, hideSender, addTopMargin, onFileNameClick, onDataUpdate, isLast } = props
    const { id, sender, type, text, metadata, confidential, created_at } = message

    const { user, userGroups } = useAuth()
    const { openErrorDialog, openWarningQuestionDialog, closeDialog, setConfirming } = useDialog()
    const [isChangingConfidentiality, setChangingConfidentiality] = useState(false)
    const [isDeleting, setDeleting] = useState(false)
    const [areOptionsVisible, setOptionsVisible] = useState(false)
    const navigate = useNavigate()

    const isClickableSender = (userGroups?.consultants && sender.partner)

    const getSpecificClass = () => {
        let specificClass = ''

        switch (type) {
            case messageType.CHAT:
                specificClass = (sender.id === user.id) ? styles.Sent : styles.Received
                break
            case messageType.ANNOTATION:
                specificClass = styles.Annotation
                break
            case messageType.UPLOAD:
                specificClass = styles.Upload
                break
        }

        if (confidential) {
            specificClass += ` ${styles.Confidential}`
        }

        if (addTopMargin) {
            specificClass += ` ${styles.TopSeparated}`
        }

        return specificClass
    }

    const getMessageText = () => {
        if (type !== messageType.UPLOAD) {
            return <span>{text}</span>
        }

        return (
            <div>
                <strong>{sender.first_name} {sender.last_name}</strong> ha adjuntado los siguientes archivos:
                <List className={styles.UploadedFilesList}>
                    {metadata?.files?.map((file) => (
                        <List.Item
                            as='a'
                            key={file}
                            className={styles.UploadedFile}
                            onClick={() => onFileNameClick(file)}
                        >
                            <Icon {...getFileIconProps(file, false)} />
                            {file}
                        </List.Item>
                    ))}
                </List>
            </div>
        )
    }

    const handleChangeMessageConfidentiality = () => {
        setChangingConfidentiality(true)

        const endpoint = confidential
            ? communicationService.makeMessagePublic
            : communicationService.makeMessageConfidential

        endpoint(id)
            .then(() => onDataUpdate())
            .catch((error) => {
                let errorMessage = error?.response?.data?.error

                if (!errorMessage) {
                    errorMessage = `Ha tenido lugar un error al actualizar el mensaje.
                                    Por favor, inténtalo de nuevo más tarde o
                                    contacta con el administrador de la plataforma.`
                }

                openErrorDialog({
                    title: 'Error al actualizar',
                    content: errorMessage,
                    size: 'tiny',
                })
            })
            .finally(() => setChangingConfidentiality(false))
    }

    const askDeleteMessageConfirmation = () => {
        setOptionsVisible(false)

        openWarningQuestionDialog({
            title: 'Confirmar borrado',
            content: `¿Estás seguro de querer borrar ${type === messageType.CHAT ? 'este mensaje' : 'esta anotación'}?`,
            size: 'mini',
            onConfirm: () => {
                setConfirming(true)
                handleDeleteMessage()
                    .then(() => {
                        setConfirming(false)
                        closeDialog()
                    })
            }
        })
    }

    const handleDeleteMessage = () => {
        setDeleting(true)

        return communicationService.deleteMessage(id)
            .then(() => onDataUpdate())
            .catch((error) => {
                let errorMessage = error?.response?.data?.error

                if (!errorMessage) {
                    errorMessage = `Ha tenido lugar un error al eliminar el mensaje.
                                    Por favor, inténtalo de nuevo más tarde o
                                    contacta con el administrador de la plataforma.`
                }

                openErrorDialog({
                    title: 'Error al eliminar',
                    content: errorMessage,
                    size: 'tiny',
                })
            })
            .finally(() => setDeleting(false))
    }

    return (
        <div className={`${styles.MessageItem} ${getSpecificClass()}`}>
            <div className={styles.MessageContent}>
                {
                !hideSender &&
                    <div
                        className={
                            `${styles.MessageSender}${isClickableSender ? ` ${styles.Clickable}` : ''}`
                        }
                        onClick={
                            isClickableSender
                                ? () => navigate(paths.USER_MANAGEMENT.replace(':userId', sender.id))
                                : undefined
                        }
                    >
                        {sender.first_name} {sender.last_name}
                    </div>
                }
                <div className={styles.MessageText}>
                    {
                        (type !== messageType.CHAT) &&
                        <Icon
                            name={type === messageType.ANNOTATION ? 'sticky note outline' : 'info circle'}
                            size='big'
                            className={styles.MessageIcon}
                        />
                    }
                    {getMessageText()}
                </div>
            </div>
            <div className={styles.MessageMeta}>
                {
                    (userGroups?.consultants && (type !== messageType.UPLOAD)) &&
                    <>
                        <div className={styles.MessageOptionsIconContainer}>
                            <Icon
                                link
                                name='chevron down'
                                className={styles.MessageOptionsIcon}
                                onClick={() => setOptionsVisible(true)}
                            />
                        </div>
                        {
                            areOptionsVisible &&
                            <MessageOptions
                                message={message}
                                onClickOutside={() => setOptionsVisible(false)}
                                onMakeConfidential={handleChangeMessageConfidentiality}
                                onDelete={askDeleteMessageConfirmation}
                                upward={isLast}
                                isChangingConfidentiality={isChangingConfidentiality}
                                isDeleting={isDeleting}
                            />
                        }
                    </>
                }
                <div className={styles.MessageTime}>
                    {datetimeToStr(created_at, true)}
                </div>
            </div>
        </div>
    )
}

export default MessageItem