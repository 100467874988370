import { monthName, weekdayName } from 'assets/constants/dates'

import styles from 'assets/styles/Common.module.scss'


const capitalize = (string) => (
    string.charAt(0).toUpperCase() + string.slice(1)
)

const dateToStr = (date, asInputValue = false) => {
    if (!date) {
        return
    }

    if (typeof date === 'string') {
        date = new Date(date)
    }

    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()

    day = day.toString().padStart(2, '0')
    month = month.toString().padStart(2, '0')

    if (asInputValue) {
        return `${year}-${month}-${day}`
    }

    return `${day}/${month}/${year}`
}

const dateToLongStr = (date) => {
    if (!date) {
        return
    }

    if (typeof date === 'string') {
        date = new Date(date)
    }

    const weekday = date.getDay()
    const day = date.getDate()
    const month = date.getMonth()
    const year = date.getFullYear()

    return `${weekdayName[weekday]}, ${day} de ${monthName[month].toLowerCase()} de ${year}`
}

const datetimeToStr = (datetime, asChat = false) => {
    if (!datetime) {
        return
    }

    if (typeof datetime === 'string') {
        datetime = new Date(datetime)
    }

    let hours = datetime.getHours()
    let minutes = datetime.getMinutes()
    let seconds = datetime.getSeconds()

    hours = hours.toString().padStart(2, '0')
    minutes = minutes.toString().padStart(2, '0')
    seconds = seconds.toString().padStart(2, '0')

    if (asChat) {
        return `${hours}:${minutes}`
    }

    return `${dateToStr(datetime)} - ${hours}:${minutes}:${seconds}`
}

const isSameDay = (firstDate, secondDate) => {
    if (typeof firstDate === 'string') {
        firstDate = new Date(firstDate)
    }
    if (typeof secondDate === 'string') {
        secondDate = new Date(secondDate)
    }

    return (
        (firstDate.getDate() === secondDate.getDate()) && 
        (firstDate.getMonth() === secondDate.getMonth()) &&
        (firstDate.getFullYear() === secondDate.getFullYear())
    )
}

const buildUrlQuery = (params) => {
    let queryUrl = ''

    if (params) {
        const queries = []

        for (let key in params) {
            let value = params[key]

            if (![undefined, null].includes(value) && (typeof value !== 'string' || value.trim() !== '')) {
                if (Array.isArray(value)) {
                    value.forEach((v) => queries.push(`${key}=${v}`))
                } else {
                    queries.push(`${key}=${value}`)
                }
            }
        }

        if (queries.length) {
            queryUrl = `?${queries.join('&')}`
        }
    }

    return queryUrl
}

const getFileIconProps = (fileName, outlineIcon = true) => {
    const iconProps = {
        name: 'file alternate'
    }

    if (fileName.match(/.(doc|docx)$/i)) {
        iconProps.name = 'file word'
        iconProps.color = 'blue'
    } else if (fileName.match(/.(jpg|jpeg|png|gif)$/i)) {
        iconProps.name = 'file image'
        iconProps.color = 'green'
    } else if (fileName.match(/.(pdf)$/i)) {
        iconProps.name = 'file pdf'
        iconProps.color = 'red'
    } else if (fileName.match(/.(xlsx|xlsm|xlsb|xltx|xltm|xls|xlt|xls|xml|xml|xlam|xla|xlw|xlr)$/i)) {
        iconProps.name = 'file excel'
        iconProps.className = styles.ExcelGreen
    } else if (fileName.match(/.(gz|bz2|rar|zip|7z)$/i)) {
        iconProps.name = 'file archive'
        iconProps.color = 'brown'
    } else {
        iconProps.color = 'grey'

        if (fileName.match(/.(pst|msg|edb|ost|eml|mbox)$/i)) {
            iconProps.name = 'envelope'
        }
    }

    if (outlineIcon) {
        iconProps.name += ' outline'
    }

    return iconProps
}

const areFilesEqual = (file1, file2) => (
    (file1?.name === file2?.name) && (file1?.type === file2?.type) &&
    (file1?.size === file2?.size) && (file1?.lastModified === file2?.lastModified)
)

export {
    capitalize,
    buildUrlQuery,
    dateToStr,
    dateToLongStr,
    datetimeToStr,
    getFileIconProps,
    areFilesEqual,
    isSameDay,
}