import { authGroups } from './auth'

const paths = {
    HOME: '/',
    LOGIN: '/login',
    REQUEST_ACCESS: '/request-access',
    PASSWORD_RESET: '/reset-password/:resetKey',
    RECORDS: '/records',
    RECORD_MANAGEMENT: '/records/:recordId',
    RECORD_COMMUNICATIONS: '/records/:recordId/communications',
    RECORD_FILES: '/records/:recordId/files',
    NEW_RECORD: '/new-record',
    MY_PROFILE: '/my-profile',
    ACCOUNTS_MANAGEMENT: '/accounts-management',
    ACCESS_REQUESTS: '/accounts-management/access-requests',
    USERS_PER_PARTNER: '/accounts-management/users-per-partner',
    PARTNER_USERS: '/accounts-management/users-per-partner/:partnerId',
    USER_MANAGEMENT: '/accounts-management/users/:userId',
    PARTNERS: '/partners',
    INSUREDS: '/insureds',
    INSURANCES: '/insurances',
    REPORTS: '/reports',
    RECORD_TYPES: '/record-types',
    CALENDAR_DAY_EVENTS: '/events/:year/:month/:day',
    NOT_FOUND: '/not-found',
    getPublic() {
        return [this.LOGIN, this.REQUEST_ACCESS, this.PASSWORD_RESET, this.NOT_FOUND]
    },
    needsLogin(path) {
        const publicPaths = this.getPublic()
        const regexList = []
        Object.entries(pathsRegexes).forEach(([key, value]) => {
            if (!publicPaths.includes(key)) {
                regexList.push(value)
            }
        })

        return regexList.some(rx => rx.test(path))
    },
    isHome(path) {
        return [this.HOME, this.RECORDS].includes(path)
    }
}

const pathsRegexes = {
    [paths.HOME]: new RegExp(`^${paths.HOME}$`),
    [paths.LOGIN]: new RegExp(`^${paths.LOGIN}$`),
    [paths.REQUEST_ACCESS]: new RegExp(`^${paths.REQUEST_ACCESS}$`),
    [paths.PASSWORD_RESET]: new RegExp(`^${paths.PASSWORD_RESET.replace(':resetKey', '.+')}$`),
    [paths.RECORDS]: new RegExp(`^${paths.RECORDS}$`),
    [paths.RECORD_MANAGEMENT]: new RegExp(`^${paths.RECORD_MANAGEMENT.replace(':recordId', '\\d+')}$`),
    [paths.RECORD_COMMUNICATIONS]: new RegExp(`^${paths.RECORD_COMMUNICATIONS.replace(':recordId', '\\d+')}$`),
    [paths.RECORD_FILES]: new RegExp(`^${paths.RECORD_FILES.replace(':recordId', '\\d+')}$`),
    [paths.NEW_RECORD]: new RegExp(`^${paths.NEW_RECORD}$`),
    [paths.MY_PROFILE]: new RegExp(`^${paths.MY_PROFILE}$`),
    [paths.ACCOUNTS_MANAGEMENT]: new RegExp(`^${paths.ACCOUNTS_MANAGEMENT}$`),
    [paths.ACCESS_REQUESTS]: new RegExp(`^${paths.ACCESS_REQUESTS}$`),
    [paths.USERS_PER_PARTNER]: new RegExp(`^${paths.USERS_PER_PARTNER}$`),
    [paths.PARTNER_USERS]: new RegExp(`^${paths.PARTNER_USERS.replace(':partnerId', '\\d+')}$`),
    [paths.USER_MANAGEMENT]: new RegExp(`^${paths.USER_MANAGEMENT.replace(':userId', '\\d+')}$`),
    [paths.PARTNERS]: new RegExp(`^${paths.PARTNERS}$`),
    [paths.INSUREDS]: new RegExp(`^${paths.INSUREDS}$`),
    [paths.INSURANCES]: new RegExp(`^${paths.INSURANCES}$`),
    [paths.REPORTS]: new RegExp(`^${paths.REPORTS}$`),
    [paths.RECORD_TYPES]: new RegExp(`^${paths.RECORD_TYPES}$`),
    [paths.CALENDAR_DAY_EVENTS]: new RegExp(`^${paths.CALENDAR_DAY_EVENTS.replace(':year', '\\d+').replace(':month', '\\d+').replace(':day', '\\d+')}$`),
    [paths.NOT_FOUND]: new RegExp(`^${paths.NOT_FOUND}$`)
}

const sideBarLinks = [
    {
        url: paths.RECORDS,
        name: 'Expedientes',
        icon: 'balance scale',
        activeForRegex: new RegExp(`^${paths.RECORDS}.*$`),
        allowedGroups: authGroups.all()
    },
    {
        url: paths.PARTNERS,
        name: 'Asociados',
        icon: 'address book outline',
        allowedGroups: [authGroups.CONSULTANTS]
    },
    {
        url: paths.INSUREDS,
        name: 'Asegurados',
        icon: 'life ring outline',
        allowedGroups: [authGroups.CONSULTANTS]
    },
    {
        url: paths.INSURANCES,
        name: 'Compañías',
        icon: 'building outline',
        allowedGroups: [authGroups.CONSULTANTS]
    },
    {
        url: paths.RECORD_TYPES,
        name: 'Tipos de expediente',
        icon: 'list alternate outline',
        allowedGroups: [authGroups.CONSULTANTS]
    },
    {
        url: paths.ACCOUNTS_MANAGEMENT,
        name: 'Usuarios',
        icon: 'users',
        activeForRegex: new RegExp(`^${paths.ACCOUNTS_MANAGEMENT}.*$`),
        allowedGroups: [authGroups.CONSULTANTS]
    },
    {
        url: paths.REPORTS,
        name: 'Informes',
        icon: 'chart pie',
        allowedGroups: [authGroups.CONSULTANTS]
    },
    {
        url: paths.NEW_RECORD,
        name: 'Nuevo expediente',
        icon: 'plus square outline',
        allowedGroups: [authGroups.BROKERS]
    }
]

export {
    paths,
    pathsRegexes,
    sideBarLinks
}