import React, { useEffect, useState } from 'react'

import NumberFormat from 'react-number-format'
import { Divider } from 'semantic-ui-react'

import { NEW_RECORD_FORM_ID } from 'assets/constants/forms'
import { dateToStr, datetimeToStr } from 'assets/utils/common'
import RecordForm from 'components/RecordForm/RecordForm'
import LastUpdateData from 'components/UI/LastUpdateData/LastUpdateData'
import PrimaryButton from 'components/UI/PrimaryButton/PrimaryButton'
import useAuth from 'hooks/useAuth'
import useDialog from 'hooks/useDialog'
import useLoader from 'hooks/useLoader'
import useNotifications from 'hooks/useNotifications'
import recordsService from 'services/records'
import styles from './RecordDataPane.module.scss'


const DataHolder = ({ title, value }) => (
    <div className={styles.DataHolder}>
        <label>{title}</label>
        <div className={styles.Value}>
            {value}
        </div>
    </div>
)


const RecordDataPane = (props) => {
    const { record, onDataUpdate, refreshCount } = props
    const {
        id,
        entry_date,
        partner,
        insured,
        insurance,
        type,
        opposite,
        policy_number,
        claim_reference,
        invoice_issued,
        invoice_number,
        invoice_amount,
        closed,
        updated_at,
        updated_by
    } = record

    const { user, userGroups } = useAuth()
    const { openErrorDialog, openSuccessDialog, closeDialog } = useDialog()
    const { showLoader, hideLoader } = useLoader()
    const [recordData, setRecordData] = useState()
    const [recordFormErrors, setRecordFormErrors] = useState(null)

    const handleFormSubmit = (formData) => {
        showLoader('Actualizando expediente...')
        setRecordFormErrors(null)
        
        recordsService.updateRecord(id, formData)
            .then((res) => {
                hideLoader()
                onDataUpdate(res.data)
                openSuccessDialog({
                    title: 'Expediente actualizado',
                    content: 'Los datos del expediente han sido actualizados correctamente.',
                    size: 'mini',
                })
            })
            .catch((error) => {
                hideLoader()

                if (error.response) {
                    setRecordFormErrors(error.response.data)
                }
            })
    }

    const formatCompanyData = (company) => (
        company ? `${company.name}${company.tax_id ? ` (${company.tax_id})` : ''}` : '-'
    )

    const formatInvoiceAmount = (amount) => (
        (typeof amount === 'number')
            ? (
                <NumberFormat
                    displayType='text'
                    value={amount}
                    suffix=' €'
                    thousandSeparator='.'
                    decimalSeparator=','
                    decimalScale={2}
                    fixedDecimalScale={true}
                />
            )
            : '-'
    )

    useEffect(() => {
        setRecordData({
            entry_date,
            type: type?.id,
            partner: partner?.id,
            insured: insured?.id,
            insurance: insurance?.id,
            policy_number,
            claim_reference,
            opposite,
            invoice_issued,
            invoice_number,
            invoice_amount,
            closed,
            updated_at,
            updated_by,
        })
    }, [record])

    return (
        <div className={styles.RecordDataPane}>
            <LastUpdateData
                date={datetimeToStr(recordData?.updated_at)}
                author={`${recordData?.updated_by.first_name} ${recordData?.updated_by.last_name}`}
            />
            <Divider hidden />
            {
                userGroups?.consultants &&
                <>
                    <RecordForm
                        initialValues={recordData}
                        onSubmit={handleFormSubmit}
                        errors={recordFormErrors}
                        refreshCount={refreshCount}
                        modality={2}
                    />
                    <div className={styles.Actions}>
                        <Divider />
                        <div className={styles.ButtonsWrapper}>
                            <PrimaryButton compact type='submit' form={NEW_RECORD_FORM_ID}>
                                Actualizar datos
                            </PrimaryButton>
                        </div>
                    </div>
                </>
            }
            {
                userGroups?.brokers &&
                <>
                    <div className={styles.Row}>
                        <DataHolder title='Fecha de alta' value={dateToStr(record.entry_date)} />
                        <DataHolder title='Tipo de expediente' value={record.type.name} />
                    </div>
                    <div className={styles.Row}>
                        <DataHolder title='Compañía' value={formatCompanyData(record.insurance)} />
                        <DataHolder title='Asegurado/tomador' value={formatCompanyData(record.insured)} />
                    </div>
                    <div className={styles.Row}>
                        <DataHolder title='Número de póliza' value={record.policy_number || '-'} />
                        <DataHolder title='Referencia siniestro' value={record.claim_reference || '-'} />
                        <DataHolder title='Contrario' value={record.opposite || '-'} />
                    </div>
                    <div className={styles.Row}>
                        <DataHolder title='Factura emitida' value={record.invoice_issued ? 'Sí' : 'No'} />
                        {
                            record.invoice_issued &&
                            <>
                                <DataHolder title='Número de factura' value={record.invoice_number || '-'} />
                                <DataHolder title='Importe de factura' value={formatInvoiceAmount(record.invoice_amount)} />
                            </>
                        }
                        <DataHolder title='Estado del expediente' value={record.closed ? 'Cerrado' : 'Abierto'} />
                    </div>
                </>
            }
        </div>
        
    )
}

export default RecordDataPane