import React, { useEffect, useMemo, useRef, useState } from 'react'

import { Icon } from 'semantic-ui-react'

import useAuth from 'hooks/useAuth'
import useDialog from 'hooks/useDialog'
import communicationService from 'services/communication'
import styles from './MessageOptions.module.scss'


const MessageOptions = (props) => {
    const { message, upward, isChangingConfidentiality, isDeleting, onClickOutside, onMakeConfidential, onDelete } = props
    const { id, sender, confidential } = message

    const { user, userGroups } = useAuth()
    const { openErrorDialog } = useDialog()
    const [openingClickPorcessed, setOpeningClickPorcessed] = useState()
    const optionsRef = useRef(null)
    const openingClickPorcessedRef = useRef(null)
    openingClickPorcessedRef.current = openingClickPorcessed

    const confidentialIcon = useMemo(() => {
        let icon

        if (isChangingConfidentiality) {
            icon = 'circle notch'
        } else if (confidential) {
            icon = 'lock open'
        } else {
            icon = 'lock'
        }

        return icon
    }, [confidential, isChangingConfidentiality])

    const handleOverallClick = (event) => {
        if (openingClickPorcessedRef.current && !optionsRef.current?.contains(event.target)) {
            onClickOutside()
        } else if (!openingClickPorcessedRef.current) {
            setOpeningClickPorcessed(true)
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleOverallClick)

        return () => {
            document.removeEventListener('click', handleOverallClick)
        }
    }, [])

    return (
        <div className={`${styles.MessageOptions}${upward ? ` ${styles.Upward}` : ''}`} ref={optionsRef}>
            {
                userGroups?.consultants &&
                <div className={styles.Option} onClick={onMakeConfidential}>
                    <Icon loading={isChangingConfidentiality} name={confidentialIcon} className={styles.Icon} />
                    <span>
                        {confidential ? 'Hacer público' : 'Hacer confidencial'}
                    </span>
                </div>
            }
            {
                (user?.id === sender.id) &&
                <div className={styles.Option} onClick={onDelete}>
                    <Icon loading={isDeleting} name={isDeleting ? 'circle notch' : 'trash'} className={styles.Icon} />
                    <span>Eliminar</span>
                </div>
            }
        </div>
    )
}

export default MessageOptions